
import {useState, useEffect} from 'react'
import * as firebase from 'firebase';
import { useCurrentUser } from './user';

export function useGlobalContacts() {
    const me = useCurrentUser()
    const [contacts, setContacts] = useState(null)
    
    useEffect(() => {
        if (null === me) return;

        firebase.database().ref(`/global_contacts`)
        .on('value', (snapshot) => {
            const data = snapshot.val()
            if (data) {
                setContacts(Object.keys(data).map((phone) => {
                    return {
                        phoneNumber: phone,
                        name: data[phone]
                    }
                }))
            }
            else {
                setContacts([])
            }
        })
    }, [me])

    return contacts
}

export function addGlobalContact() {
    const user = firebase.auth().currentUser 
    firebase.database().ref(`/global_contacts/${user.phoneNumber}`)
    .set(user.displayName)
}

export function temporaryGlobalContact() {
    const user = firebase.auth().currentUser 
    const ref = firebase.database().ref(`/global_contacts/${user.phoneNumber}`)
    ref.set(user.displayName)
    ref.onDisconnect().remove()
}