import React from 'react'
import { Redirect } from 'react-router'
import { useCurrentUser } from '../hooks/user'

export function UserRestricted({children}) {
    const user = useCurrentUser()

    if (null === user) {
        return null
    }
    else if (false === user || !user.displayName) {
        return <Redirect to='/login'/>
    }
    return (
        <>
            {children}
        </>
    )
}

export function GuestRestricted({children}) {
    const user = useCurrentUser()

    if (null === user) {
        return null
    }
    else if (user && user.displayName) {
        return <Redirect to='/'/>
    }
    return (
        <>
            {children}
        </>
    )
}