import React from 'react';

export const MicrophoneIcon = (props) => {
    return (
        <svg {...props} height="16px" version="1.1" viewBox="0 0 16 16" width="16px">
            <title />
            <defs />
            <g fill="none" fillRule="evenodd" id="Icons with numbers" stroke="none" strokeWidth="1">
                <g fill={props.fill} id="Group" transform="translate(-720.000000, -48.000000)">
                    <path d="M733,54 L733,57 C733,59 731,61 729,61 L729,63 L732,63 L732,64 L725,64 L725,63 L728,63 L728,61 C726,61 724,59 724,57 L724,54 L725,54 L725,57 C725,58.5 726.5,60 728,60 L729,60 C730.5,60 732,58.5 732,57 L732,54 Z M728.004882,48 L728.995118,48 C730.1061,48 731,48.8918564 731,49.992017 L731,57.007983 C731,58.0998238 730.102384,59 728.995118,59 L728.004882,59 C726.8939,59 726,58.1081436 726,57.007983 L726,49.992017 C726,48.9001762 726.897616,48 728.004882,48 Z M728.004882,48" id="Shape" />
                </g>
            </g>
        </svg>
    )
}
MicrophoneIcon.defaultProps = { fill: '#000' }

export const PlayIcon = (props) => {
    return (
        <svg {...props} height="512px" id="Layer_1"  version="1.1" viewBox="0 0 512 512" width="512px">
            <path d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20  c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z" />
        </svg>
    )
}
PlayIcon.defaultProps = { fill: '#000' }