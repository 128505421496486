import * as firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCwDVE8inf6lnLYqchRUPzIOv-U7itgY4A",
    authDomain: "chitatalk.firebaseapp.com",
    databaseURL: "https://chitatalk.firebaseio.com",
    projectId: "chitatalk",
    storageBucket: "chitatalk.appspot.com",
    messagingSenderId: "353964714115",
    appId: "1:353964714115:web:a9c4e3f9ff2b4c4e4d53b6",
    measurementId: "G-E5Q41T5DM7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().useDeviceLanguage()