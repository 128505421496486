/* getUserMedia fix */
export const fixUserMedia = () => {
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
    }
    
    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {

            // First get ahold of the legacy getUserMedia, if present
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            // Some browsers just don't implement it - return a rejected promise with an error
            // to keep a consistent interface
            if (!getUserMedia) {
                return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }

            // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
            return new Promise(function(resolve, reject) {
                getUserMedia.call(navigator, constraints, resolve, reject);
            });
        }
    }
}
  
/* AudioContext fix */
const fixAudioContext = (audioContext) => {
    // Create empty buffer
    var buffer = audioContext.createBuffer(1, 1, audioContext.sampleRate);
    var source = audioContext.createBufferSource();
    source.buffer = buffer;
    // Connect to output (speakers)
    source.connect(audioContext.destination);
    // Play sound
    if (source.start) {
        source.start(0);
    } else if (source.play) {
        source.play(0);
    } else if (source.noteOn) {
        source.noteOn(0);
    }
    source.disconnect()
}

window.AudioContext = window.AudioContext || window.webkitAudioContext
export const createAudioContext = () => {
    const audioContext = new window.AudioContext()
    fixAudioContext(audioContext)
    audioContext.close()
    return new window.AudioContext()
}