import { useState, useEffect, useCallback } from "react";
import { config } from "../config";
import * as firebase from 'firebase';
import { useCurrentUser } from "./user";
import { useQueue } from "./general";

const signaling = {
    sendCandidateToServer: async (me,contact,candidate) => {
        firebase.database().ref(`/connections/${contact.phoneNumber}/${me.phoneNumber}/candidates/answer`).onDisconnect().remove()
        await firebase.database().ref(`/connections/${contact.phoneNumber}/${me.phoneNumber}/candidates/answer`)
        .push(candidate.toJSON())
    },
    sendCandidateToClient: async (me,contact,candidate) => {
        await firebase.database().ref(`/connections/${me.phoneNumber}/${contact.phoneNumber}/candidates/offer`)
        .push(candidate.toJSON())
    },
    sendOfferToClient: (me,contact,offer,onAnswer,onCandidates) => {
        /* cleanup */
        firebase.database().ref(`/connections/${me.phoneNumber}/${contact.phoneNumber}`).onDisconnect().remove()
        /* on answer */
        firebase.database().ref(`/connections/${me.phoneNumber}/${contact.phoneNumber}/answer`)
        .on('value', (snapshot) => {
            const answer = snapshot.val();
            if (answer) {
                onAnswer(answer)
            }
        })
        /* on candidates */
        firebase.database().ref(`/connections/${me.phoneNumber}/${contact.phoneNumber}/candidates/answer`)
        .on('value', (snapshot) => {
            const data = snapshot.val()
            for (let key in data) {
                const candidate = new RTCIceCandidate(data[key])
                onCandidates(candidate)
            }
        })
        /* register offer */
        firebase.database().ref(`/connections/${me.phoneNumber}/${contact.phoneNumber}/offer`)
        .set({
            type: offer.type,
            sdp: offer.sdp,
        })
    },
    sendAnswerToServer: async (me,contact,answer,onCandidate) => {
        firebase.database().ref(`/connections/${contact.phoneNumber}/${me.phoneNumber}/answer`).onDisconnect().remove()
        firebase.database().ref(`/connections/${contact.phoneNumber}/${me.phoneNumber}/candidates/offer`)
        .on('value', (snapshot) => {
            const data = snapshot.val()
            for (let key in data) {
                const candidate = new RTCIceCandidate(data[key])
                onCandidate(candidate)
            }
        })

        await firebase.database().ref(`/connections/${contact.phoneNumber}/${me.phoneNumber}/answer`)
        .set({
            type: answer.type,
            sdp: answer.sdp
        })

    },
    onOfferFromServer: async (me,contact,callback) => {
        firebase.database().ref(`/connections/${contact.phoneNumber}/${me.phoneNumber}/offer`)
        .on('value', (snapshot) => {
            const offer = snapshot.val()
            if (offer) {
                callback(offer)
            }
        })
    },
};

export function useContactServer(contact) {
    const me = useCurrentUser()
    const [peerConnection, setPeerConnection] = useState(null)
    const [channel, setChannel] = useState(null)
    const [state, setState] = useState(null)

    useEffect(() => {
        console.log(`server: ${state}`)
    }, [state])
    
    useEffect(() => {
        console.log(`server: new RTCPeerConnection`)
        const pc = new RTCPeerConnection(config.webrtc)
        setPeerConnection(pc)
        return () => { 
            setPeerConnection(null)
            pc.close()
        }
    }, [contact])

    useEffect(() => {
        if (peerConnection) {
            setChannel(peerConnection.createDataChannel('data'))
        }
    }, [setChannel, peerConnection])

    useEffect(() => {
        if (!me) return;
        if (!peerConnection) return;

        peerConnection.onicecandidate = (event) => {
            if (event.candidate && peerConnection.signalingState !== 'closed') {
                signaling.sendCandidateToClient(me, contact, event.candidate)
            }
        }

        setState(peerConnection.connectionState)
        peerConnection.onconnectionstatechange = (event) => {
            setState(peerConnection.connectionState)
        }

        peerConnection.onsignalingstatechange = event => {
            if (peerConnection.signalingState === 'closed') {
                const pc = new RTCPeerConnection(config.webrtc)
                setPeerConnection(pc)
            }
        }

        async function createOfferAndHandle() {
            const offer = await peerConnection.createOffer()
            peerConnection.setLocalDescription(offer).then(() => {
                signaling.sendOfferToClient(me, contact, offer,
                (answer)=> { /* onAnswer */
                    if (peerConnection.signalingState !== 'closed') {
                        peerConnection.setRemoteDescription(answer)
                    }
                },
                (candidate)=> { /* onCandidate */
                    if (peerConnection.signalingState !== 'closed') {
                        peerConnection.addIceCandidate(candidate)
                    }
                })
            })
        }
        createOfferAndHandle()

    }, [peerConnection, me, contact])

    return [state, channel]
}

export function useContactClient(contact) {
    const me = useCurrentUser()
    const [peerConnection, setPeerConnection] = useState(null)
    const [channel, setChannel] = useState(null)
    const [state, setState] = useState(null)

    useEffect(() => {
        console.log(`client: ${state}`)
    }, [state])

    useEffect(() => {
        console.log(`client: new RTCPeerConnection`)
        const pc = new RTCPeerConnection(config.webrtc)
        setPeerConnection(pc)
        return () => { 
            setPeerConnection(null)
            pc.close()
        }
    }, [contact])

    useEffect(() => {
        if (!me) return;
        if (!peerConnection) return;

        peerConnection.onicecandidate = (event) => {
            if (peerConnection.signalingState === 'closed') { return }
            if (event.candidate) {
                signaling.sendCandidateToServer(me, contact, event.candidate)
            }
        }

        setState(peerConnection.connectionState)
        peerConnection.onconnectionstatechange = (event) => {
            setState(peerConnection.connectionState)
        }

        peerConnection.onsignalingstatechange = event => {
            if (peerConnection.signalingState === 'closed') {
                const pc = new RTCPeerConnection(config.webrtc)
                setPeerConnection(pc)
            }
        }

        peerConnection.ondatachannel = (event) => {
            setChannel(event.channel)
        }

        /* handle offer */
        signaling.onOfferFromServer(me, contact, async (offer) => {
            if (peerConnection.signalingState === 'closed') { return }

            peerConnection.setRemoteDescription(offer).then(() => {
                peerConnection.createAnswer().then(answer => {
                    peerConnection.setLocalDescription(answer).then(() => {
                        signaling.sendAnswerToServer(me, contact, answer,
                        (candidate) => { /* onCandidate */ 
                            if (peerConnection.signalingState === 'closed') { return }
                            peerConnection.addIceCandidate(candidate)
                        })
                    })
                })
            })
        })
    }, [peerConnection, me, contact])

    return [state, channel]
}

const STATE_HIRARCHY = {
    'disconnected': 0,
    'failed': 1,
    'connecting': 2,
    'connected': 3,
}

export function useContactConnection(contact) {
    const [serverState, serverChannel] = useContactServer(contact)
    const [clientState, clientChannel] = useContactClient(contact)
    const [state, setState] = useState(serverState)
    const [sendQueue, sendQueuePush] = useQueue()
    const [recvQueue, recvQueuePush] = useQueue()

    useEffect(() => {
        if (STATE_HIRARCHY[serverState] >= STATE_HIRARCHY[clientState]) {
            setState(serverState)
        }
        else {
            setState(clientState)
        }
    }, [serverState, clientState])

    const sendToChannel = useCallback((channel) => {
        sendQueue.getAll((messages) => {
            for (const message of messages) {
                channel.send(message)
            }
            return []
        })
    }, [sendQueue])

    useEffect(() => {
        if (!sendQueue.empty) {
            if (serverState === 'connected' && serverChannel && serverChannel.readyState === 'open') {
                sendToChannel(serverChannel)
            }
            else if (clientState === 'connected' && clientChannel && clientChannel.readyState === 'open') {
                sendToChannel(clientChannel)
            }
        }
    }, [sendToChannel, sendQueue, serverState, clientState, serverChannel, clientChannel])

    const onChannelOpen = useCallback((event) => {
        const channel = event.target
        sendToChannel(channel)
    }, [sendToChannel])

    const onChannelMessage = useCallback((event) => {
        recvQueuePush(event.data)
    }, [recvQueuePush])

    useEffect(() => {
        if (serverChannel) {
            serverChannel.onopen = onChannelOpen
            serverChannel.onmessage = onChannelMessage
        }
    }, [serverChannel, onChannelOpen, onChannelMessage])

    useEffect(() => {
        if (clientChannel) {
            clientChannel.onopen = onChannelOpen
            clientChannel.onmessage = onChannelMessage
        }
    }, [clientChannel, onChannelOpen, onChannelMessage])

    return {
        state: state,
        send: useCallback((message) => {
            sendQueuePush(message)
        }, [sendQueuePush]),
        recvQueue,
    }
}