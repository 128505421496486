import React from 'react'
import { UserRestricted } from '../elements/restrictions'
import { ContactsGroup } from '../elements/contact'
import { useCurrentUser } from '../hooks/user'
import { useGlobalContacts, temporaryGlobalContact } from '../hooks/contacts'
import { MediaProvider } from '../hooks/media';

export function HomePage() {
    const me = useCurrentUser()
    const gcontacts = useGlobalContacts()

    if (me) {
        temporaryGlobalContact()
    }

    let contacts = gcontacts? gcontacts : []
    if (me) {
        contacts = contacts.filter(contact => contact.phoneNumber !== me.phoneNumber)
    }
    return (
        <UserRestricted>
            <MediaProvider>
                <center>
                    <h1>Chita Talk</h1>
                </center>
                {contacts.length > 0?
                    <ContactsGroup key={'A'} contacts={contacts}/>
                : contacts.length === 0?
                   <label>No one else is logged in :(</label>
                : 'Loading...'}
            </MediaProvider>
        </UserRestricted>
    )
}