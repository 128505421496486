import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './firebase/init';
import { HomePage } from './pages/home';
import { LoginPage } from './pages/login';

function App() {
  return (
    <div style={{position: 'fixed', left: 0, top: 0, height: '100vh', width: '100vw', overflow: 'hidden'}}>
      <BrowserRouter>
        <Switch>
          <Route exact path='/login'>
            <LoginPage/>
          </Route>
          <Route exact path='/'>
            <HomePage/>
          </Route>
          <Route path='*'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
