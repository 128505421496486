import React, { useState, useEffect, useCallback } from 'react'
import './contact.css'
import { useContactConnection } from '../hooks/connection';
import BufferQueueNode from 'web-audio-buffer-queue'
import { useMicAudioProcessor, resampleAudioBuffer, useAudioContext } from '../hooks/media';

import { MicrophoneIcon, PlayIcon } from './resources/icons'
import { config } from '../config';

const BORDER_COLOR_MAP = {
    'disconnected': '#747474', 
    'connecting': '#00008b',
    'connected': '#00ba00',
    'failed': 'red',
}

export function ContactsGroup({contacts}) {
    return (
        <div className='contacts-group'>
            {contacts.map((contact) => {
                return <Contact key={contact.phoneNumber} contact={contact}/>
            })}
        </div>
    )
}

export function Contact({contact}) {
    const {state, send: sendMsg, recvQueue} = useContactConnection(contact)
    const [bufferQueue, setBufferQueue] = useState(null)
    const [touchActive, setTouchActive] = useState(false)
    const [listenHover, setListenHover] = useState(false)
    const [recordHover, setRecordHover] = useState(false)
    const audioContext = useAudioContext()

    useEffect(() => {
        if (bufferQueue) {
            if (!recvQueue.empty) {
                recvQueue.getAll((messages) => {
                    for (const message of messages) {
                        try {
                            const f32a = decodeFloat32Array(message)
                            const audioBuffer = audioContext.createBuffer(1, f32a.length, config.audio.sampleRate)
                            audioBuffer.getChannelData(0).set(f32a)
                            resampleAudioBuffer(audioBuffer, audioContext.sampleRate)
                            .then(resampledAudioBuffer => {
                                console.log(resampledAudioBuffer)
                                bufferQueue.write(resampledAudioBuffer.getChannelData(0))
                            })
                        }
                        catch (error) {
                            alert(`error at ${error.lineNumber}: ${error.message}`)
                        }
                    }
                    return []
                })
            }
        }
    }, [bufferQueue, recvQueue, audioContext])

    useEffect(() => {
        if (!bufferQueue || !audioContext) return;

        if (listenHover) {
            bufferQueue.connect(audioContext.destination)
        }
        else {
            bufferQueue.disconnect()
        }
    }, [listenHover, bufferQueue, audioContext])

    const initializeAudioResources = useCallback((audioContext) => {
        const bufferQueue = new BufferQueueNode({
            audioContext: audioContext,
            objectMode: true,
        })
        setBufferQueue(bufferQueue)
    }, [setBufferQueue])

    useEffect(() => {
        if (audioContext) {
            initializeAudioResources(audioContext)
        }
    }, [initializeAudioResources, audioContext])

    const handleTouchStart = event => {
        setTouchActive(true)
   }

    const handleTouchEnd = event => {
        setTouchActive(false)
        setListenHover(false)
        setRecordHover(false)
    }

    const handleTouchMove = event => {
        const location = event.changedTouches[0];
        const target = document.elementFromPoint(location.clientX, location.clientY);

        if (target && target.id === 'listen') {
            if (listenHover !== true) {
                setListenHover(true)
            }
        }
        else {
            if (listenHover !== false) {
                setListenHover(false)
            }
        }

        if (target && target.id === 'record') {
            if (recordHover !== true) {
                setRecordHover(true)
            }
        }
        else {
            if (recordHover !== false) {
                setRecordHover(false)
            }
        }
    }

    const handleAudioRecorded = useCallback(buffer => {
        sendMsg(buffer)
    }, [sendMsg])

    const renderStyle = () => {
        return {
            borderColor: BORDER_COLOR_MAP[state],
        }
    }

    return (
        <>
            <div 
                className={ (!touchActive) ? 'contact' : 'contact active'}
                style={renderStyle()}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
            >
                {contact.name}
                <ContactListenButton
                    key='listen'
                    isHover={listenHover}
                    disabled={bufferQueue? bufferQueue._queue.length === 0 : true}
                />
                <ContactRecordButton
                    key='record'
                    isHover={recordHover}
                    onAudioRecorded={handleAudioRecorded}
                />
            </div>
            <div className='contact-shadow'></div>
        </>
    )
}

function ContactListenButton({isHover, disabled}) {
    const renderStyle = () => {
        return {
            opacity: (disabled) ? 0.3 : 1,
        }
    }


    let className='contact-button contact-listen'
    if (isHover) {
        className += ' hover'
    }
    return (
        <>
            <div
                id='listen'
                className={className}
                style={renderStyle()}
            >
                <PlayIcon fill={(isHover && !disabled)? '#0f0' : '#fff'}/>
            </div>
            <div className='play-reminder'>
                {(!disabled) ?
                    <PlayIcon fill='#ccc' />
                : null}
            </div>
        </>
    )
}

function ContactRecordButton({isHover, onAudioRecorded}) {
    const audioProcessor = useMicAudioProcessor()

    useEffect(() => {
        if (!audioProcessor) { return };

        if (isHover) {
            audioProcessor.onaudioprocess = event => {
                const audioBuffer = event.inputBuffer
                console.log(audioBuffer)
                resampleAudioBuffer(audioBuffer, config.audio.sampleRate).then((resampledAudioBuffer) => {
                    const channelData = resampledAudioBuffer.getChannelData(0)
                    onAudioRecorded(encodeFloat32Array(channelData))
                })
            }
        }
        else {
            setTimeout(()=> {
                audioProcessor.onaudioprocess = null
            }, [200])
        }
    }, [audioProcessor, isHover, onAudioRecorded])

    let className='contact-button contact-record'
    if (isHover) {
        className += ' hover'
    }
    return (
        <div
            id='record'
            className={className}
        >
            <MicrophoneIcon fill={isHover? '#f00' : '#fff'}/>
        </div>
    )
}

function encodeFloat32Array(f32) {
    // code 
    // let f32json = JSON.stringify(f32);
    // let f32jsonArr = JSON.stringify(Array.from(f32));
    let f32base64 = btoa(String.fromCharCode(...(new Uint8Array(f32.buffer))));
    // let f32base128 = ... // not trivial, look belo

    return f32base64
}

function decodeFloat32Array(f32base64) {
    // decode
    // let df32json = new Float32Array(Object.values(JSON.parse(f32json))); 
    // let df32jsonArr = new Float32Array(JSON.parse(f32jsonArr));
    let df32base64 = new Float32Array(new Uint8Array([...atob(f32base64)].map(c => c.charCodeAt(0))).buffer);
    // let df32base128 = ... // not trivial, look below

    return df32base64
}