import { useState, useCallback } from "react";

export function useQueue() {
    const [queue, setQueue] = useState([])

    const push = useCallback((item) => {
        setQueue(oldItems => [...oldItems, item])
    }, [setQueue]);

    const get = (callback) => {
        const item = queue[0];
        const result = callback(item)
        if (result) {
            setQueue(oldItems => {
                oldItems.splice(0, 1)
                return [...oldItems]
            })
        }
    }

    const getAll = (callback) => {
        const result = callback(queue)
        if (result) {
            setQueue(result)
        }
    }

    return [{
        empty: (queue.length === 0),
        get,
        getAll,
    }, push]
}