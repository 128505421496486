import React, { useState, useRef, useEffect, useCallback } from 'react'
import * as firebase from 'firebase'
import { GuestRestricted } from '../elements/restrictions'
import './login.css'
import { useCurrentUser, setUserName } from '../hooks/user'
import { useHistory } from 'react-router'

export function LoginPage() {
    const me = useCurrentUser()
    return (
        <GuestRestricted>
            <div className='login-page'>
                <h1>Chita Talk</h1>
                { me === null? 
                    null
                : !me?
                    <LoginWithSMS />
                :
                    <UserNameEntrance user={me}/>
                }
            </div>
        </GuestRestricted>
    )
}

function LoginWithSMS() {
    const phoneInputRef = useRef()
    const sendInputRef = useRef()
    const codeInputRef = useRef()
    const [codeSent, setCodeSent] = useState(false)
    const [confirmationResult, setConfirmationResult] = useState(null)
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null)

    const sendCode = useCallback(() => {
        const phone = phoneInputRef.current.value
        /* TODO: validations ... */
        firebase.auth().signInWithPhoneNumber('+972' + phone, recaptchaVerifier)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            setCodeSent({'codeSent': true})
            setConfirmationResult(confirmationResult)
        })
        .catch((error) => {
            console.log(error)
            /* TODO: better handling */
            if (error.code === 'auth/invalid-phone-number')
                alert('מספר פלאפון לא תקין')
            else
                alert(error.message)

        })
    }, [phoneInputRef, recaptchaVerifier])

    useEffect(() => {
        if (sendInputRef.current && !recaptchaVerifier) {
            setRecaptchaVerifier(
                new firebase.auth.RecaptchaVerifier('sendCode', {
                    'size': 'invisible',
                    'callback': function() {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        sendCode();
                    },
                })
            )
        }
    }, [sendInputRef, recaptchaVerifier, sendCode])

    const submitCode = () => {
        if (!confirmationResult) {
            return
        }

        const code = codeInputRef.current.value
        confirmationResult.confirm(code)
        .then((result) => {
        })
    }

    return (
        <div className='login-section'>
            { !codeSent ?
                <>
                    <label>Enter your phone number</label>
                    <input
                        key='phoneNumber'
                        ref={phoneInputRef}
                        type='text'
                        placeholder='phone number'
                        disabled={codeSent}
                    /> 
                    <input
                        key='phoneSubmit'
                        ref={sendInputRef}
                        id='sendCode'
                        type='button'
                        value='Send Code'
                        onClick={sendCode}
                        disabled={codeSent}
                    /> 
                </>
            :
                <>
                    <label>Enter the code you received</label>
                    <input
                        key='codeNumber'
                        ref={codeInputRef}
                        type='number'
                        placeholder='code'
                        disabled={!codeSent}
                    /> 
                    <input
                        key='codeSubmit'
                        type='button'
                        value='Submit Code'
                        onClick={submitCode}
                        disabled={!codeSent}
                    /> 
                </>
            }
        </div>
    )
}

function LoginWithGoogle() {
    const login = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            // ...
          }).catch(function(error) {
            alert(error.message)
          });
    }

    return (
        <>
            <input type='button' onClick={login} value='Continue with Google'/>
        </>
    )
}

function UserNameEntrance() {
    const nameInput = useRef(null)
    const history = useHistory()

    const submitName = () => {
        const name = nameInput.current.value;
        setUserName(name)
        .then(() => {
            history.push('/')
        })
    }

    return (
        <div className='login-section'>
            <label>Almost finished, enter some Name</label>
            <input
                key='name'
                ref={nameInput}
                type='text'
                placeholder='Name'
            /> 
            <input
                key='nameSubmit'
                type='button'
                value='Done'
                onClick={submitName}
            /> 
        </div>
    )
}