import React, {useEffect, useState, useContext, createContext} from 'react'
import { fixUserMedia, createAudioContext } from '../mediafix'
import { MessageBox } from '../elements/msgbox'

const MediaContext = createContext(null)

export function MediaProvider({children}) {
    const [audioProcessor, setAudioProcessor] = useState(null)

    useEffect(() => {
        fixUserMedia()
    }, [])

    const initializeMedia = (event) => {
        window.audioContext = createAudioContext()

        /* IOS keep open */
        const audioProcessor = window.audioContext.createScriptProcessor(1024, 1, 1)
        audioProcessor.connect(window.audioContext.destination)

        navigator.mediaDevices.getUserMedia({audio: true})
        .then((stream) => {
            const audioSource = window.audioContext.createMediaStreamSource(stream)
            audioSource.connect(audioProcessor)
            setAudioProcessor(audioProcessor)
        })
        .catch((error) => {
            alert(error)
        })
    }

    return (
        <MediaContext.Provider value={{audioProcessor}}>
            <MessageBox
                shown={true}
                title={`Microphone Permission for Chita Talk`}
                description={`The app needs your permission to us the Microphone.`}
                buttons={[<input type='button' onClick={initializeMedia} value='OK'/>]}
                forceAnswer={true}
            />
            {children}
        </MediaContext.Provider>
    )
}

export function useMicAudioProcessor() {
    const {audioProcessor} = useContext(MediaContext)
    return audioProcessor
}

export function useAudioContext() {
    const {audioProcessor} = useContext(MediaContext)
    const [audioContext, setAudioContext] = useState(null)

    useEffect(() => {
        if (audioProcessor) {
            setAudioContext(window.audioContext)
        }
    }, [audioProcessor])

    return audioContext
}

export function resampleAudioBuffer(srcAudioBuffer, sampleRate) {
    return new Promise((resolve) => {
        const myOfflineAudioContext =
            window.OfflineAudioContext || window.webkitOfflineAudioContext;

        var offlineCtx = new myOfflineAudioContext(srcAudioBuffer.numberOfChannels, srcAudioBuffer.duration * srcAudioBuffer.numberOfChannels * sampleRate, sampleRate);
        var cloneBuffer = offlineCtx.createBuffer(srcAudioBuffer.numberOfChannels, srcAudioBuffer.length, srcAudioBuffer.sampleRate);

        // Copy the source data into the offline AudioBuffer
        for (var channel = 0; channel < srcAudioBuffer.numberOfChannels; channel++) {
            cloneBuffer.getChannelData(channel).set(
                srcAudioBuffer.getChannelData(channel)
            );
        }

        // Play it from the beginning.
        var source = offlineCtx.createBufferSource();
        source.buffer = cloneBuffer;
        source.connect(offlineCtx.destination);
        source.start(0);
        offlineCtx.oncomplete = function(resampled) {
            resolve(resampled.renderedBuffer)
        }

        offlineCtx.startRendering();
    })
}