export const config = {
    audio: {
      sampleRate: 44100,
    },
    webrtc: {
        iceServers: [
            {
              urls: [
                'stun:stun.l.google.com:19302',
                /*
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
                'stun:stun3.l.google.com:19302',
                'stun:stun4.l.google.com:19302',
                */
              ],
            },
            {
              urls: 'turn:turn.chita.space:3478',
              username: 'turn.chita.space',
              credential: 'turn.chita.space123',
            },
        ],
        iceCandidatePoolSize: 10,
    },
}